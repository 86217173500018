import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Axios from "axios";
function ProduitSection() {
  const [station, setStation] = useState([]);
  const [gnss, setGnss] = useState([]);
  const [scanner, setScanner] = useState([]);
  const [niveaux, setNiveaux] =useState([]);
  useEffect(() => {
    Axios.get("/frontend/stotal")
      .then((res) => {
        setStation(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    Axios.get("/frontend/gnss")
      .then((res) => {
        setGnss(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    Axios.get("/frontend/scaner")
      .then((res) => {
        setScanner(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    Axios.get("/frontend/niveaux")
    .then((res) =>{
      setNiveaux(res.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
  }, [])
  return (
    <section className="w3l-features-8">
      <div className="heading text-center mx-auto mt-5 mb-4">
        <h3 className="head"> S.S.T</h3>
        <h2 className=" head"> One Company,Two Brands </h2>
      </div>

      <Container>
        <Row className="mt-3 ">
          {station.map((item) => (
            <Col key={item.uuid} xs={12} lg={6} md={6} className="mt-3">
              <div className="float-top">
                <Link
                  to={{
                    pathname: `/produit/${item.subcatName}/${item.id}`,
                    hash: "toppage",
                  }}
                >
                  <img
                    src={`../../images/${item.subcatimgPath}`}
                    className="img-fluid-ps"
                    alt=""
                  />
                </Link>
                 
                  <h3   >
                    <Link
                      to={{
                        pathname: `/produit/${item.subcatName}/${item.id}`,
                        hash: "toppage",
                      }}
                    >
                      {item.subcatName} {item.category.catName}
                    </Link>
                  </h3>

                  <Link
                    to={{
                      pathname: `/produit/${item.subcatName}/${item.id}`,
                      hash: "toppage",
                    }}
                    className="btn-button btn-secondary-button"
                  >
                    {" "}
                    Voir Plus
                  </Link>
                </div>
              
            </Col>
          ))} 
        </Row>
       
        <Row className="mt-3 ">
          {gnss.map((val) => (
            <Col key={val.uuid} xs={12} lg={6} md={6} className="mt-3">
              <div className="float-top">
                <Link
                  to={{
                    pathname: `/produit/${val.subcatName}/${val.id}`,
                    hash: "toppage",
                  }}
                >
                  <img
                    src={`../../images/${val.subcatimgPath}`}
                    className="img-fluid-ps mb-3"
                    alt=""
                  />
                </Link>
                 
                  <h3>
                    <Link
                      to={{
                        pathname: `/produit/${val.subcatName}/${val.id}`,
                        hash: "toppage",
                      }}
                    >
                      {val.subcatName} {val.category.catName}{" "}
                    </Link>{" "}
                  </h3>

                  <Link
                    to={{
                      pathname: `/produit/${val.subcatName}/${val.id}`,
                      hash: "toppage",
                    }}
                    className="btn-button btn-secondary-button"
                  >
                    {" "}
                    Voir Plus
                  </Link>
                </div>
             
            </Col>
          ))}
            </Row>
           
            <Row className="mt-3 ">
          {scanner.map((value) => (
            <Col key={value.uuid} xs={12} lg={6} md={6} className="mt-3">
              <div className="float-top">
                <Link
                  to={{
                    pathname: `/${value.produitName}/${value.uuid}`,
                    hash: "toppage",
                  }}
                >
                  <img
                    src={`../../images/${value.thumbnail}`}
                    className="img-fluid-ps mb-3"
                    alt=""
                  />
                </Link>
            
                  <h3>
                    <Link
                      to={{
                        pathname: `/${value.produitName}/${value.uuid}`,
                        hash: "toppage",
                      }}
                    >
                      {value.produitName}
                    </Link>{" "}
                  </h3>

                  <Link
                    to={{
                      pathname: `/${value.produitName}/${value.uuid}`,
                      hash: "toppage",
                    }}
                    className="btn-button btn-secondary-button"
                  >
                    {" "}
                    Voir Plus
                  </Link>
                </div>
               
            </Col>
          ))}
          {niveaux.map((item) => (
             <Col key={item.uuid} xs={12} lg={6} md={6} className="mt-3">
             <div className="float-top">
               <Link
                 to={{
                   pathname: `/produit/${item.subcatName}/${item.id}`,
                   hash: "toppage",
                 }}
               >
                 <img
                   src={`../../images/${item.subcatimgPath}`}
                   className="img-fluid-ps"
                   alt=""
                 />
               </Link>
             
                 <h3>
                   <Link
                     to={{
                       pathname: `/produit/${item.subcatName}/${item.id}`,
                       hash: "toppage",
                     }}
                   >
                     {item.subcatName} {item.category.catName}
                   </Link>
                 </h3>

                 <Link
                   to={{
                     pathname: `/produit/${item.subcatName}/${item.id}`,
                     hash: "toppage",
                   }}
                   className="btn-button btn-secondary-button"
                 >
                   {" "}
                   Voir Plus
                 </Link>
               </div>
             
           </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default ProduitSection;
