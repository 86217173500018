import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

function EditSubCat({ match }) {
  const history = useHistory();
  const [subCat, setSubCat] = useState({
    uuid: "",
    subcatName: "",
    subcatimgPath: "",
    categoryId: "",
    subcatDisc: "",
    bannerimgPath: "",
  });
  const [cat, setCat] = useState([]);
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choisir Image");
  const [banner, setBanner] = useState("");
  const [bannerName, setBannerName] = useState("Choisir Image de Couverture");
  const onChangeImg = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };
  const onChangeBanner = (e) => {
    setBanner(e.target.files[0]);
    setBannerName(e.target.files[0].name);
  };
  useEffect(() => {
    Axios.get("/produit/category")
      .then((res) => {
        setCat(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  useEffect(() => {
    Axios.get(`/produit/subcategory/${match.params.uuid}`)
      .then((result) => setSubCat(result.data[0]))
      .catch((error) => console.log(error.message));
  }, [match.params.uuid]);
  const onChange = (e) => {
    setSubCat({ ...subCat, [e.target.name]: e.target.value });
  };
  const updateSubCat = async (e) => {
    e.preventDefault();
    const updatedSubCat = new FormData();
    updatedSubCat.append("subCatImg", file);
    updatedSubCat.append("bannerSubCat", banner);
    updatedSubCat.append("uuid", subCat.uuid);
    updatedSubCat.append("subcatName", subCat.subcatName);
    updatedSubCat.append("subcatimgPath", subCat.subcatimgPath);
    updatedSubCat.append("categoryId", subCat.categoryId);
    updatedSubCat.append("subcatDisc", subCat.subcatDisc);
    updatedSubCat.append("bannerimgPath", subCat.bannerimgPath);

    await Axios.put(`/produit/subcategory/${match.params.uuid}`, updatedSubCat)
      .then((res) => {
        console.log(res.data);
        history.push("/gest-Sub-Category");
      })
      .catch((err) => console.log(err.message));
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1 className="mb-3">Modifier Sous Catégorie </h1>

          <form onSubmit={updateSubCat} encType="multipart/form-data">
            <div className="form-group">
              <label htmlFor="name">Catégorie</label>
              <select
                required
                className="form-control focus"
                id="name"
                name="categoryId"
                aria-describedby="emailHelp"
                value={subCat.categoryId}
                onChange={onChange}
              >
                <option disabled>Choisissez Catégorie</option>
                {cat.map((cat) => (
                  <option key={cat.uuid} value={cat.id}>
                    {cat.catName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                className="form-control"
                id="nom"
                name="subcatName"
                aria-describedby="emailHelp"
                value={subCat.subcatName}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="disc">Description</label>
              <textarea
                className="form-control"
                id="disc"
                name="subcatDisc"
                aria-describedby="emailHelp"
                value={subCat.subcatDisc}
                onChange={onChange}
              />
            </div>
            <div className="row">
              <div className="custom-file mb-4 col-md-6 col-lg-6 mt-3">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={onChangeImg}
                  name="subcatimgPath"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {filename}
                </label>
              </div>
              <div className="custom-file  my-3 mb-1 col-md-6 col-lg-6  text-right">
                <div>
                  <img
                    src={`../../images/${subCat.subcatimgPath}`}
                    style={{ width: "40%", height: "40%", marginBottom: "5%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="custom-file mb-4 col-md-6 col-lg-6 mt-5">
                <label htmlFor="name">Image de Couverture</label>
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={onChangeBanner}
                  name="bannerimgPath"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {bannerName}
                </label>
              </div>
              <div className="custom-file  my-3 mb-1 col-md-6 col-lg-6  text-right">
                <div>
                  <img
                    src={`../../images/${subCat.bannerimgPath}`}
                    style={{ width: "40%", height: "40%", marginTop: "5%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="btn-button btn-secondary-button">
              Modifier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditSubCat;
