import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from "react-router-hash-link";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
function GestCaroussel() {
  // load data from database
  const [caroussel, setCaroussel] = useState([]);
  useEffect(() => {
    Axios.get("/produit/caroussel")
      .then((res) => {
        setCaroussel(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  const deleteCarousel = async (uuid, e) => {
    await Axios.delete(`/produit/caroussel/${uuid}`);
    setCaroussel((prevCaroussel) =>
      prevCaroussel.filter((item) => item.uuid !== uuid)
    );
    setModal(false);
  };
  const [modal, setModal] = useState(false);
  const [uuid, setUuid] = useState("");

  const toggle = (uuid) => {
    setModal(!modal);
    setUuid(uuid);
  };
  const closeModal = () => {
    setModal(!modal);
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div
          className="col-lg-12 col-md-12 d-flex justify-content-between"
          style={{ backgroundColor: "#000000" }}
        >
          <h1 className="justify-content-start" style={{ color: "#FFFFFF" }}>
            Ajouter Images Caroussel
          </h1>
          <Link
            to="/gest-caroussel/add-caroussel"
            style={{ color: "inherit", marginTop: "1%" }}
          >
            <button className="btn-button btn-secondary-button-admin justify-content-end">
              Ajouter{" "}
            </button>
          </Link>
        </div>
        <div className="col-md-12 col-lg-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">titre</th>
                <th scope="col">description</th>
                <th scope="col">Image</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {caroussel.map((item) => (
                <tr key={item.uuid}>
                  <th style={{ width: "20%" }}>{item.title}</th>
                  <td style={{ width: "20%" }}>{item.disc}</td>
                  <td style={{ width: "30%" }}>
                    <img
                      src={"../../images/" + item.pathImage}
                      style={{ width: "20%" }}
                      alt="caroussel img"
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => toggle(item.uuid)}
                    >
                      Supprimer{" "}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: "#ffffff" }}
                      />{" "}
                    </button>{" "}
                    <Link
                      to={`/edit-carousel/${item.uuid}`}
                      style={{ color: "inherit" }}
                    >
                      {" "}
                      <button className="btn btn-info">
                        Modifier{" "}
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "#FFFFFF" }}
                        />
                      </button>{" "}
                    </Link>
                  </td>
                </tr>
              ))}
              <Modal isOpen={modal} uuid={uuid} toggle={(e) => closeModal}>
                <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
                <ModalBody>L'image Sera Supprimée Définitivement</ModalBody>
                <ModalFooter>
                  <Button
                    color="btn btn-danger"
                    onClick={(e) => deleteCarousel(uuid)}
                  >
                    Supprimer{" "}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ color: "#FFFFFF" }}
                    />{" "}
                  </Button>
                  <Button color="secondary" onClick={(e) => closeModal()}>
                    Annuler
                  </Button>
                </ModalFooter>
              </Modal>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GestCaroussel;
