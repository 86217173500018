import React, { useState, useEffect } from "react";
import Axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProduitsDetail({ match }) {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [product, setProduct] = useState([]);
  useEffect(() => {
    Axios.get(`/frontend/produits/${match.params.uuid}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <div className="container-pp" id="toppage">
        {product.map((item) =>
          item.bannerimgPath !== null ? (
            <>
              <img
                key={item.uuid}
                src={"/images/" + item.bannerimgPath}
                alt="Snow"
                className="banner-img"
              />
              <div className="centered">
                <div className="box">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </>
          ) : (
            <div
              className="container pb-5 mb-5"
              style={{
                backgroundColor: "white",
                color: "white",
              }}
            >
              &nbsp;
            </div>
          )
        )}
      </div>
      <>
        <div className="container" id="page_produit">
          <div className="row mt-5 mb-5">
            <div className="col-lg-6 col-md-6 col-12 mt-3 mb-3" id="page_produit">
              {product.map((item) => (
                <Slider {...settings} key={item.uuid}>
                  {item.images.map((val) => (
                    <div className="d-block w-80" key={val.uuid}>
                      <img
                        key={val.id}
                        src={"../images/" + val.pathImage}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  ))}
                </Slider>
              ))}
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-3  ">
              {product.map((item) => (
                <div key={item.uuid}>
                  <h1 className="mb-3">{item.produitName}</h1>
                  <p className="mb-3" style={{ whiteSpace: "pre-line" }}>
                    {item.produitDisc}
                  </p>
                  {item.fichTechnique ? (
                    <a href={"/files/" + item.fichTechnique} className="media-fb" download>
                      Fiche Technique
                    </a>
                  ) : null}
                  <br />
                  {item.brochure ? (
                    <a href={"/files/" + item.brochure} className="media-fb" download>
                      Brochure
                    </a>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default ProduitsDetail;
