import React, { useState, useEffect } from "react";
import Axios from "axios";
import Message from "./Message";
import Progress from "./Progress";
import { useHistory } from "react-router-dom";
function AddImages() {
  const history = useHistory();

  const [produit, setProduit] = useState([]);
  useEffect(() => {
    Axios.get("/produit/produits")
      .then((res) => {
        setProduit(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  //store data
  const [produitId, setProduitId] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesName, setSelectedImagesName] = useState("images");
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const imagesHandler = (e) => {
    setSelectedImages(e.target.files);
    setSelectedImagesName(e.target.files.name);
  };
  const store = async (e) => {
    e.preventDefault();
    const newImages = new FormData();
    newImages.append("produitId", produitId);
    for (let i = 0; i < selectedImages.length; i++) {
      newImages.append("images", selectedImages[i]);
    }

    try {
      await Axios.post("/produit/images", newImages, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );

          // Clear form
          setTimeout(() => setUploadPercentage(0), 2000);
          setTimeout(() => setMessage(""), 1000);
          setTimeout(() => setSelectedImages([]), 1000);
          setTimeout(() => setSelectedImagesName("images"), 1000);
          setTimeout(() => history.push("/gest-images"), 2050);
        },
      });

      setMessage("Images Uploaded");
    } catch (error) {}
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Ajouter Images Produit </h1>
          <br />
          {message ? <Message msg={message} /> : null}
          <form onSubmit={store} encType="multipart/form-data" method="post">
            <div className="form-group">
              <label for="category">Produit</label>
              <select
                id="category"
                className="form-control"
                value={produitId}
                onChange={(e) => setProduitId(e.target.value)}
              >
                <option disabled>Choisissez Produit</option>
                {produit.map((produit) => (
                  <option key={produit.id} value={produit.id}>
                    {produit.produitName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group   mb-3">
              <label className="form-group-label" htmlFor="customFile">
                {selectedImagesName}
              </label>
              <input
                type="file"
                className="form-control"
                id="customFile"
                multiple
                accept="image/png,image/jpeg"
                onChange={imagesHandler}
              />
            </div>
            <Progress percentage={uploadPercentage} />
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddImages;
