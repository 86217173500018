import React from 'react'
import { Col, Container,Row } from 'react-bootstrap';
import "../fontello-9bb3aaa9/css/fontello.css";
function Company() {
    return (
        <section className="w3l-services2" id="stats" style={{linearGradient:"(to right, rgba(42, 42, 42, 0.71), rgba(38, 40, 40, 0.78)",backgroundImage:"url(images/ab-1.jpg)"}}>
			<div className="features-with-17_sur py-5">
				   <Container className="container py-md-3">
					
				     <Row className="row pt-lg-5 mt-lg-3">
						<Col className="col-lg-6 col-12 features-with-17-left_sur">
							<h4 className="lft-head"> Surveying Systems Tunisia</h4>
						<p>S.S.T est le distributeur des instruments de topographie du SOKKIA - TOPCON pour les secteurs de l'arpentage et de la construction .</p>
						</Col>
						<Col className="col-lg-2 col-4 my-lg-0 my-5 ">
							<div className=" text-center">
								 
									<span><i className="icon-construction-and-tools" style={{color:"#F29400",fontSize:"55px"}}/></span>		
								 
 									 
									<p  >Experts   Géomètres</p>		
								 
							</div>
							</Col>
							<Col className="col-lg-2 col-4 my-lg-0 my-5 ">
							<div className=" text-center">
								 
										<span ><i className="icon-university-with-a-flag" style={{color:"#F29400",fontSize:"55px"}}/></span>		
								 
								          
										<p >Les établissements de l'état</p>			
								 
							</div>
							</Col>
							<Col className="col-lg-2 col-4 my-lg-0 my-5 ">
							<div className=" text-center">
							 
									<span><i className="icon-hotel" style={{color:"#F29400",fontSize:"55px"}}/></span>		
								 
										 
										<p >entreprise T.P et construction</p>	
							 
							</div>
							
							
							
						</Col>
					</Row>
				</Container>
			</div>
		</section>
    )
}

export default Company
