import React, { useState } from "react";
import Axios from "axios";
import Message from "./Message";
import Progress from "./Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const schema = yup.object().shape({
  file: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (
        (value && value[0].type === "image/jpeg") ||
        (value && value[0].type === "image/png")
      );
    }),
  banner: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (
        (value && value[0].type === "image/jpeg") ||
        (value && value[0].type === "image/png")
      );
    }),
  name: yup.string().required("ce champ est obligatoire"),
});
function AddCategory() {
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const newCat = new FormData();
    newCat.append("catImg", data.file[0]);
    newCat.append("name", data.name);
    newCat.append("banner", data.banner[0]);
    try {
      await Axios.post("http://localhost:4000/produit/category", newCat, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
          //Clear form
          setTimeout(() => setUploadPercentage(0), 1000);
          setTimeout(() => setMessage(""), 1000);
          setTimeout(() => history.push("/gest-cat"), 1100);
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.message);
        });
      setMessage("File Uploaded");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Ajouter Catégorie </h1>
          <br />
          {message ? <Message msg={message} /> : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            method="post"
          >
            <div className="form-group">
              <label htmlFor="name">nom</label>
              <input
                type="text"
                className={`${
                  errors.name ? `form-control is-invalid` : `form-control`
                }`}
                id="name"
                name="name"
                aria-describedby="emailHelp"
                ref={register}
              />
              <div className="error ">{errors.name?.message}</div>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="name">Image</label>
              <input
                type="file"
                className={`${
                  errors.file ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                ref={register}
                name="file"
              />
              <div className="error ">{errors.file?.message}</div>
            </div>

            <div className="form-group mb-4">
              <label htmlFor="name">Image de Couverture</label>
              <input
                type="file"
                className={`${
                  errors.banner ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                name="banner"
                ref={register}
              />
              <div className="error ">{errors.banner?.message}</div>
            </div>

            <Progress percentage={uploadPercentage} />

            <br />
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
