import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from "react-router-hash-link";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function GestSubCategory() {
  //load data from db

  const [subCat, setSubCat] = useState([]);
  useEffect(() => {
    Axios.get("/produit/subcategory")
      .then((res) => {
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const deleteSubcat = async (id, e) => {
    await Axios.delete(`/produit/subcategory/${id}`);
    setSubCat((prevSubbcat) => prevSubbcat.filter((item) => item.id !== id));
    setModal(false);
  };
  const [id, setId] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setModal(!modal);
    setId(id);
  };
  const closeModal = () => {
    setModal(!modal);
  };

  return (
    <div className="container">
      <div className="row row-a">
        <div
          className="col-lg-12 col-md-12 d-flex justify-content-between"
          style={{ backgroundColor: "#000000" }}
        >
          <h1 className="justify-content-start" style={{ color: "#FFFFFF" }}>
            Ajouter Sous Catégorie{" "}
          </h1>
          <Link
            to="/gest-Sub-Category/add-subcat"
            style={{ color: "inherit", marginTop: "1%" }}
          >
            {" "}
            <button className="btn-button btn-secondary-button-admin justify-content-end">
              Ajouter
            </button>
          </Link>
        </div>
        <div className="col-md-12 col-lg-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Image</th>
                <th scope="col">Category</th>
                <th scope="col">Action </th>
              </tr>
            </thead>
            <tbody>
              {subCat.map((subCat) => (
                <tr key={subCat.uuid}>
                  <th>{subCat.subcatName}</th>
                  <td style={{ width: "30%" }}>
                    <img
                      src={"../../images/" + subCat.subcatimgPath}
                      style={{ width: "20%" }}
                      alt="category img"
                    />
                  </td>
                  <td>{subCat.category.catName}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => toggle(subCat.id)}
                    >
                      {" "}
                      Supprimer{" "}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: "#FFFFFF" }}
                      />
                    </button>{" "}
                    <Link to={`/edit-subcategory/${subCat.uuid}`}>
                      <button className="btn btn-info">
                        Modifier{" "}
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "#FFFFFF" }}
                        />
                      </button>
                    </Link>{" "}
                  </td>
                </tr>
              ))}
              <Modal isOpen={modal} id={id} toggle={(e) => closeModal}>
                <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
                <ModalBody>
                  La Sous Catégorie Sera Supprimée Définitivement
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="btn btn-danger"
                    onClick={(e) => deleteSubcat(id)}
                  >
                    Supprimer{" "}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ color: "#FFFFFF" }}
                    />{" "}
                  </Button>
                  <Button color="secondary" onClick={(e) => closeModal()}>
                    Annuler
                  </Button>
                </ModalFooter>
              </Modal>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GestSubCategory;
