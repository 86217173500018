import React, { useEffect, useState } from "react";
import Axios from "axios";
import Message from "./Message";
import Progress from "./Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const schema = yup.object().shape({
  subcatImg: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (
        (value && value[0].type === "image/jpeg") ||
        (value && value[0].type === "image/png")
      );
    }),
  banner: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (
        (value && value[0].type === "image/jpeg") ||
        (value && value[0].type === "image/png")
      );
    }),
  nom: yup.string().required("ce champ est obligatoire"),
  disc: yup.string().required("ce champ est obligatoire"),
  name: yup.string().required("ce champ est obligatoire"),
});
function AddSubCat() {
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [cat, setCat] = useState([]);
  useEffect(() => {
    Axios.get("/produit/category")
      .then((res) => {
        setCat(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const newSubCat = new FormData();
    newSubCat.append("subcatImg", data.subcatImg[0]);
    newSubCat.append("banner", data.banner[0]);
    newSubCat.append("nom", data.nom);
    newSubCat.append("disc", data.disc);
    newSubCat.append("id", data.name);

    try {
      await Axios.post("/produit/subcategory", newSubCat, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );

          // Clear form
          setTimeout(() => setUploadPercentage(0), 2000);
          setTimeout(() => setMessage(""), 1000);
          setTimeout(() => history.push("/gest-Sub-Category"), 2050);
        },
      });

      setMessage("File Uploaded");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1 className="mb-3">Ajouter Sous Catégorie </h1>
          {message ? <Message msg={message} /> : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            method="post"
          >
            <div className="form-group">
              <label htmlFor="name">Catégorie</label>
              <select
                required
                className={`${
                  errors.name ? `form-control is-invalid` : `form-control`
                }`}
                id="name"
                name="name"
                aria-describedby="emailHelp"
                ref={register}
              >
                <option disabled>Choisissez Catégorie</option>
                {cat.map((cat) => (
                  <option key={cat.uuid} value={cat.id}>
                    {cat.catName}
                  </option>
                ))}
              </select>
              <div className="error ">{errors.name?.message}</div>
            </div>
            <div className="form-group">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                className={`${
                  errors.nom ? `form-control is-invalid` : `form-control`
                }`}
                id="nom"
                name="nom"
                aria-describedby="emailHelp"
                ref={register}
              />
              <div className="error ">{errors.nom?.message}</div>
            </div>

            <div className="form-group">
              <label htmlFor="disc">Description</label>
              <textarea
                className={`${
                  errors.disc ? `form-control is-invalid` : `form-control`
                }`}
                id="disc"
                name="disc"
                aria-describedby="emailHelp"
                ref={register}
              />
              <div className="error ">{errors.disc?.message}</div>
            </div>
            <div className="form-group mb-4">
              <label htmlFor="name">Image</label>

              <input
                type="file"
                className={`${
                  errors.subcatImg ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                ref={register}
                name="subcatImg"
              />
              <div className="error ">{errors.subcatImg?.message}</div>
            </div>
            <div className="form-group mb-4">
              <label htmlFor="name">Image de Couverture</label>

              <input
                type="file"
                className={`${
                  errors.banner ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                ref={register}
                name="banner"
              />
              <div className="error ">{errors.banner?.message}</div>
            </div>
            <Progress percentage={uploadPercentage} />

            <br />
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddSubCat;
