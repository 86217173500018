import React, { useState } from "react";
import "../../App.css";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

function UserNav() {
  const { logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="dark" dark expand="md">
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          <NavItem>
            <NavLink to="/gest-cat" className="nav-link">
              Gestion Catégorie
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/gest-Sub-Category" className="nav-link">
              {" "}
              Gestion Sous Catégorie
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/gest-Produit" className="nav-link">
              Gestion Produit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/gest-images" className="nav-link">
              Gestion Images Produit{" "}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/gest-caroussel" className="nav-link">
              {" "}
              Gestion Caroussel{" "}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/gest-banner" className="nav-link">
              {" "}
              Gestion Banners
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/logout"
              exact
              className="nav-link"
              onClick={() => logout()}
            >
              {" "}
              Log out{" "}
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default UserNav;
