import React from 'react';
import AboutUs from './AboutUs';
import CarouselHomePage from './CarouselHomePage';
import Contact from './Contact';
import Company from './Company';
import ProduitSection from './ProduitSection';
import CustomersCarousel from './CustomersCaroussel' 
import FacebookHomePage from './FacebookHomePage'
import {Error} from "../components/Error"
function Accueil() {
    return (
        <div>
           <CarouselHomePage/>
           <Contact/>
           <AboutUs/>
           <Company/>
           <ProduitSection/>
           <CustomersCarousel/>
           <Error>
           <FacebookHomePage/>
           </Error>
        </div>
    )
}

export default Accueil
