import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

function EditCat({ match }) {
  const history = useHistory();
  const [cat, setCat] = useState({
    uuid: "",
    catName: "",
    imgPath: "",
    bannerImgPath: "",
  });
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choisir Image");
  const [banner, setBanner] = useState("");
  const [bannerName, setBannerName] = useState("Choisir Image de Couverture");
  const onChangeImg = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };
  const onChangeBanner = (e) => {
    setBanner(e.target.files[0]);
    setBannerName(e.target.files[0].name);
  };

  useEffect(() => {
    Axios.get(`/produit/category/${match.params.uuid}`)
      .then((result) => setCat(result.data[0]))
      .catch((error) => console.log(error.message));
  }, [match.params.uuid]);

  const onChange = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value });
  };
  const updateCategory = async (e) => {
    e.preventDefault();
    const updatedCat = new FormData();
    updatedCat.append("catImg", file);
    updatedCat.append("banner", banner);
    updatedCat.append("uuid", cat.uuid);
    updatedCat.append("catName", cat.catName);
    updatedCat.append("imgPath", cat.imgPath);
    updatedCat.append("bannerImgPath", cat.bannerImgPath);

    await Axios.put(`/produit/category/${match.params.uuid}`, updatedCat)
      .then((res) => {
        console.log(res.data);
        history.push("/");
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div className="container mt-5">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Modifier Catégorie </h1>
          <br />
          <form onSubmit={updateCategory} encType="multipart/form-data">
            <div className="form-group">
              <label htmlFor="name">nom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="catName"
                value={cat.catName}
                onChange={onChange}
              />
            </div>

            <div className="custom-file mb-4 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input "
                id="customFile"
                name="imgPath"
                onChange={onChangeImg}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {filename}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                <img
                  src={`../../images/${cat.imgPath}`}
                  style={{ width: "40%", height: "40%" }}
                  alt=""
                />
              </div>
            </div>

            <div className="custom-file mb-4 mt-4 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                name="bannerImgPath"
                onChange={onChangeBanner}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {bannerName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-1 col-md-6 col-lg-6  text-right">
              <div>
                <img
                  src={`../../images/${cat.bannerImgPath}`}
                  style={{ width: "40%", height: "40%" }}
                  alt=""
                />
              </div>
            </div>
            <button type="submit" className="btn-button btn-secondary-button">
              Modifier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditCat;
