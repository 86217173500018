import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
function EditCarousel({ match }) {
  const history = useHistory();
  const [img, setImg] = useState({
    uuid: "",
    pathImage: "",
    title: "",
    disc: "",
    lien: "",
  });
  const [carousel, setCarousel] = useState("");
  const [carouselName, setCarouselName] = useState("Choisir Image");
  const onChangeImg = (e) => {
    setCarousel(e.target.files[0]);
    setCarouselName(e.target.files[0].name);
  };
  useEffect(() => {
    Axios.get(`/produit/carousel/${match.params.uuid}`)
      .then((result) => setImg(result.data[0]))
      .catch((error) => console.log(error.message));
  }, [match.params.uuid]);

  const onChange = (e) => {
    setImg({ ...img, [e.target.name]: e.target.value });
  };
  const updateCarousel = async (e) => {
    e.preventDefault();
    const updatedCarousel = new FormData();
    updatedCarousel.append("carousselImg", carousel);
    updatedCarousel.append("uuid", img.uuid);
    updatedCarousel.append("namepathImg", img.pathImage);
    updatedCarousel.append("title", img.title);
    updatedCarousel.append("disc", img.disc);
    updatedCarousel.append("lien", img.lien);
    await Axios.put(`/produit/carousel/${match.params.uuid}`, updatedCarousel)
      .then((res) => {
        history.push("/gest-caroussel");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Modifer L'image de Carousel </h1>
          <br />

          <form onSubmit={updateCarousel} encType="multipart/form-data">
            <div className="form-group">
              <label htmlFor="title">Titre</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={img.title}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                aria-describedby="emailHelp"
                name="disc"
                value={img.disc}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lien">Lien</label>
              <select
                className="form-control"
                name="lien"
                aria-describedby="emailHelp"
                value={img.lien}
                onChange={onChange}
              >
                <option value="/produit">Produit Page</option>
                <option value="/service">Service Page</option>
                <option value="/a-propos">A propos Page</option>
                <option value="/contactez-nous">Contactez Nous Page</option>
              </select>
            </div>

            <div className="custom-file mb-3 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={onChangeImg}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {carouselName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                <img
                  src={`../../images/${img.pathImage}`}
                  style={{ width: "40%", height: "40%" }}
                  alt=""
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Modifier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditCarousel;
