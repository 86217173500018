import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";

function topFunction() {
    document.body.scrollTop = 0;
   document.documentElement.scrollTop = 0;
}

function FooterUser() {
    return (
        <section className="w3l-footer-29-main" id="footer"  >
    
     
    <button onClick={topFunction} id="movetop" title="Go to top" ><span ><FontAwesomeIcon  icon={faAngleDoubleUp}/></span></button>
  </section> 
       
    )
}

export default FooterUser
