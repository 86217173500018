import React, { useState, useEffect } from "react";
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";

function Produit() {
  const [cat, setCat] = useState([]);
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const page = new FormData();
    page.append("link", "/produit");
    Axios.post("/frontend/banner", page)
      .then((res) => setBanner(res.data))
      .catch((err) => console.log(err.message));
  }, []);
  useEffect(() => {
    Axios.get("/produit/category")
      .then((res) => {
        setCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <div className="  container-pp" id="toppage">
        {banner.map((item) => (
          <img
            src={`../../images/${item.imgPath}`}
            key={item.uuid}
            alt="Snow"
            className="banner-img"
          />
        ))}
        <div className="centered">
          <div className="box">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <>
        <section className="news-1" id="blog">
          <div className="news py-5 ">
            <div className="container py-md-3 ">
              <div className="heading text-center mx-auto">
                <h3 className="head">Produits</h3>
                <p className="my-3 head">
                Explorez les dernières technologies qui aident les géomètres à obtenir les mesures les plus précises et les innovations les plus récentes .

                </p>
              </div>
              <div className="blog-grids row mt-5 ">
                {cat.map((item) => (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3" key={item.uuid}>
                    <div className="blog-grid" id="zoomIn">
                      <Link to={`/brand/${item.catName}/${item.id}`}>
                        <figure>
                          <img
                            src={"images/" + item.imgPath}
                            className=" testflex"
                            alt=""
                          />
                        </figure>
                      </Link>
                      <div className="">
                        <h3>
                          <Link to={`/brand/${item.catName}/${item.id}`}>
                            {item.catName}
                          </Link>{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default Produit;
