import React, { useState } from "react";
import { Carousel, Container } from "react-bootstrap";

function CustomersCaroussel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <section className="w3l-customers-4" id="testimonials">
      <div id="customers4-block" className="py-5">
        <Carousel
          activeIndex={index}
          indicators={false}
          onSelect={handleSelect}
          style={{
            backgroundImage: "url(images/ser-1.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Carousel.Item>
            <Container className="container ">
              <div className="carousel-inner ">
                <div className="section-title">
                  <div className="item-top">
                    <div className="item text-center">
                      <div className="imgTitle">
                        <img
                          className="img-responsive"
                          src="images/0.png"
                          alt="First slide"
                          style={{ width: "40vh" }}
                        />
                      </div>
                      <h6 className="mt-3">S.S.T</h6>

                      <h5>" One Company Two Brands " </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Carousel.Item>

          <Carousel.Item>
            <Container className="container ">
              <div className="carousel-inner ">
                <div className="section-title">
                  <div className="item-top">
                    <div className="item text-center">
                      <div className="imgTitle">
                        <img
                          className="img-responsive"
                          src="images/0.png"
                          alt="seconde slide"
                          style={{ width: "40vh" }}
                        />
                      </div>
                      <h6 className="mt-3">S.S.T</h6>

                      <h5>" Le Premier Service Technique en Tunisie " </h5>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
    //         <section className="w3l-customers-4" id="testimonials">
    //   <div id="customers4-block" className="py-5">
    //     <div className="container py-md-3">

    //       <div id="customerhnyCarousel" className="carousel slide" data-ride="carousel">

    //         <ol className="carousel-indicators">
    //           <li data-target="#customerhnyCarousel" data-slide-to="0" className="active"></li>
    //           <li data-target="#customerhnyCarousel" data-slide-to="1"></li>
    //           <li data-target="#customerhnyCarousel" data-slide-to="2"></li>
    //         </ol>

    //         <div className="carousel-inner pb-5 mb-md-5">

    //           <div className="carousel-item active">
    //             <div className="section-title">
    //               <div className="item-top">
    //                 <div className="item text-center">
    //                   <div className="imgTitle">
    //                     <img src="images/c1.jpg" className="img-responsive" alt="" />
    //                   </div>
    //                   <h6 className="mt-3">Steve Smith</h6>
    //                   <p className="">Client</p>
    //                   <h5>" Magna aliqua. Ut enim ad minim veniam, quis nostrud.Lorem ipsum dolor " </h5>

    //                 </div>
    //               </div>
    //             </div>
    //           </div>

    //           <div className="carousel-item">
    //             <div className="section-title">
    //               <div className="item-top">
    //                 <div className="item text-center">
    //                   <div className="imgTitle">
    //                     <img src="images/c2.jpg" className="img-responsive" alt="" />
    //                   </div>
    //                   <h6 className="mt-3">Jessey Rider</h6>
    //                   <p className="">Worker</p>
    //                   <h5>" Magna aliqua. Ut enim ad minim veniam, quis nostrud.Lorem ipsum dolor " </h5>

    //                 </div>
    //               </div>
    //             </div>

    //           </div>
    //           <div className="carousel-item">
    //             <div className="section-title">
    //               <div className="item-top">
    //                 <div className="item text-center">
    //                   <div className="imgTitle">
    //                     <img src="images/c3.jpg" className="img-responsive" alt="" />
    //                   </div>
    //                   <h6 className="mt-3">Mark Stoins</h6>
    //                   <p className="">Engineer</p>
    //                   <h5>" Magna aliqua. Ut enim ad minim veniam, quis nostrud.Lorem ipsum dolor " </h5>

    //                 </div>
    //               </div>
    //             </div>
    //           </div>

    //         </div>

    //       </div>

    //     </div>
    //   </div>

    // </section>
  );
}

export default CustomersCaroussel;
