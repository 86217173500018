import React, { useState, useEffect } from "react";
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";
function CatDetail({ match }) {
  const [subCat, setSubCat] = useState([]);
  useEffect(() => {
    Axios.get(`/frontend/brand/${match.params.id}`)
      .then((res) => {
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [match.params.id]);
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    Axios.get(`/frontend/catbanner/${match.params.id}`)
      .then((res) => setBanner(res.data))
      .catch((err) => console.log(err.message));
  }, [match.params.id]);
  return (
    <>
      <div className="container-pp" id="toppage">
        {banner.map((item) => (
          <img
            src={`../../images/${item.bannerImgPath}`}
            alt="Snow"
            className="banner-img"
            key={item.uuid}
          />
        ))}
        <div className="centered">
          <div className="box">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row w3l-features-8">
          {subCat.map((val, key) => (
            <div className="col-md-6 col-12 col-lg-4 mt-1  " key={key}>
              <div className="float-top-pc">
                <Link smooth to={{pathname:`/produit/${val.subcatName}/${val.id}`,hash:"toppage"}}>
                  <img
                    src={"../../images/" + val.subcatimgPath}
                    className="img-fluid-ps"
                    alt=""
                  />
                </Link>

                <div className="float-lt feature-gd ">
                  <h3>
                    <Link smooth to={{pathname:`/produit/${val.subcatName}/${val.id}`,hash:"toppage"}}>
                      {val.subcatName}
                    </Link>
                  </h3>
                  <p>{val.subcatDisc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CatDetail;
