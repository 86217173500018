import React, { useReducer, useEffect, Fragment } from "react";
import { faThumbsUp, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";

const initialState = {
  loading: true,
  error: "",
  post: {},
};
const reducer = (state, action) => {
  switch (action.type) {
    case "fetch success":
      return {
        loading: false,
        post: action.payload,
        error: "",
      };
    case "fetch error":
      return {
        loading: false,
        post: {},
        error: "something went wrong ",
      };
    default:
      return state;
  }
};

function FacebookHomePage() {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    Axios.get(
      "https://graph.facebook.com/v9.0/me?fields=posts.limit(3)%7Bpicture%2Cfull_picture%2Cid%2Cmessage%2Ccreated_time%2Clikes.summary(true)%2Cpermalink_url%7D&access_token=EAAfukQQbQo0BAEhIlETl42wQ7pbc1nueoDINZAgksMgFCRwFnIt3vFdjfvuvW7zo158pseG2jQFEw9F3hnzOR4Y2N085JxVQVULaWAfcnFUok6FMAZCtiGvMytRpPmnGSAw44aP1frFswgPaFCMpLt8aXTwaiG1ME8l51tWbrSwWmmqaof"
    )

      .then((res) => {
        dispatch({ type: "fetch success", payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: "fetch error" });
      });
  }, []);
  return (
    <section className="news-1" id="blog">
      <div className="news">
        <div className="container ">
          <div className="heading text-center mx-auto">
            <h3 className="head">Nouveautés</h3>
          </div>
          <div className="blog-grids row mt-5 mb-3">
          {state.loading ? (
                  "loading"
                ) : (<>
               {state.post.posts.data.map((item,key)=>(
                 <Fragment key={key}><div className="col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-4" >
              <div className="blog-grid" id="zoomIn">
                
                  <figure key={item.id}>
                    <img
                      src={item.full_picture}
                      className="img-fluid-fb"
                      alt="facebook posts"
                    />{" "}
                  </figure>
               

                <div className="blog-info">
                 
                    <p>
                      {" "}
                      {item.message.substring(0, 100)}
                      <a
                        href={item.permalink_url}
                        rel="noreferrer"
                        target="_blank"
                        className="nav-link"
                      >
                        Voir Plus{" "}
                      </a>
                    </p>
                 
                  <ul>
                    <li>
                      <span className="mr-2">
                        <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>
                      </span>
                      {item.likes.summary.total_count}
                       
                    </li>
                    <li>
                      <span className="mr-2">
                        <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                      </span>
                      {new Date(item.created_time).toISOString().slice(0, 10)}
                          
                    </li>
                  </ul>
                </div>
                
              </div>
            </div></Fragment>
                ))}
                </> )}
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default FacebookHomePage;
