import React, { useState, useEffect } from "react";
import Axios from "axios";
function Service() {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const page = new FormData();
    page.append("link", "/a-propos");
    Axios.post("/frontend/banner", page)
      .then((res) => setBanner(res.data))
      .catch((err) => console.log(err.message));
  }, []);
  return (
    <>
      <div className="container-pp" id="toppage">
        {banner.map((item) => (
          <img
            src={`../../images/${item.imgPath}`}
            alt="Snow"
            className="banner-img"
          />
        ))}
        <div className="centered">
          <div className="box">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      
      <div className="container mt-lg-3 mt-md-3 mt-2">
        <div className="row" id="sav">
          <h1>S.A.V</h1>
          <div className="col-md-6  col-lg-6 col-12">
            <p className="lead">
              Notre service technique possède tout l’équipement nécessaire pour
              assurer une vérification rapide de vos instruments. En outre, le
              centre technique de S.S.T ,mis en place en 1996,permet à nos
              techniciens qualifiés de procéder à l’entretien complet et à la
              réparation des instruments du mesure et topographie .
            </p>
            <ul className="ml-5">
              <li className="ml-5">
                Service Technique autorisé par SOKKIA & TOPCON
              </li>
              <li className="ml-5">
                Service d’inspection, de calibration et de certification
                annuelle
              </li>
              <li className="ml-5">Nettoyage et mises à jour logicielles</li>
              <li className="ml-5">
                Réparations sous garantie pour les instruments SOKKIA & TOPCON
              </li>
              <li className="ml-5">
                Techniciens expérimentés et formé en continu
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-6 col-12"></div>
        </div>
        <div id="formation" className="row my-3">
          <h1>Formation & Accompagnement </h1>

          
          <div className="col-md-6 order-md-12 col-12">
            <p className="lead">
              Tout au long de chacune des étapes suivant l’achat ou la location
              d’instruments spécialisés, SST se distingue en vous accompagnant
              étroitement, afin que votre utilisation de ces instruments,
              L’équipe SST s’assurera de vous offrir tout le support nécessaire
              pour permettre à votre entreprise d’être tout à fait autonome dans
              l’utilisation qu’elle désire en faire. Qu’il s’agisse de vous
              guider lors d’une première utilisation ou de former une équipe
              complète en chantier, nous saurons vous proposer la solution qui
              répond le mieux à vos besoins.
            </p>
            <ul className="ml-5">
              <li className="ml-5">Formation et démonstrations</li>
              <li className="ml-5">Présence en chantier</li>
              <li className="ml-5">Main d’œuvre technique</li>
            </ul>
          </div>
          <div className="col-md-6 order-md-1  col-12 "></div>

        </div>
        <div id="support" className="row my-3">
          <h1>Support Produit</h1>
          <div className="col-md-6 col-12">
            <p className="lead">
              Parce que la satisfaction de nos clients passe par la qualité de
              nos instruments et, surtout de notre service, SST se fait un point
              d’honneur de mettre en tout temps à leur disposition une équipe
              expérimentée capable d’offrir les services après-vente qui fera la
              différence.{" "}
            </p>
            <ul className="ml-5">
              <li className="ml-5">Support à l’achat</li>
              <li className="ml-5">Support technique</li>
            </ul>
           </div>
          <div className="col-md-6 col-12"></div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
}

export default Service;
