import React from 'react'
import { HashLink as Link} from 'react-router-hash-link'


function NotMatch() {
    return (
        <div className="container container-nm">
            <div className="row row-a">
                <div className="col-md-6">
                    <h1>OOPS !</h1>
                    <p className="text-justify text-capitalize">
                    nous n’arrivons pas à trouver la page que vous cherchez <br/>
                     voici quelques liens utiles à la place :
                    </p>
                    <ul className="ml-5"> 
                  
                        <li>
                        <li><Link smooth to={{pathname:"/produit",hash:"toppage"}}>Produits</Link></li>
                        <li><Link smooth to={{pathname:"/Service",hash:"toppage"}}>Services</Link></li>
                        <li><Link smooth to={{pathname:"/a-propos",hash:"toppage"}}>A Propos</Link></li>
                        <li><Link smooth to={{pathname:"/contactez-nous",hash:"toppage"}}>Contactez-Nous</Link></li> 
                        </li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <div>
                        <img src="../../logo.svg" style={{width:"50%",height:"50%"}} alt='logo' />
                    </div>
                    <br/>
                    <h1 className="text-capitalize">
                     Partenaire Des Topographes
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default NotMatch
