import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from "react-router-hash-link";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function GestProduit() {
  //load data

  const [produit, setProduit] = useState([]);
  useEffect(() => {
    Axios.get("/produit/produits")
      .then((res) => {
        setProduit(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // delete data from datanase
  const deleteProduit = async (id, e) => {
    await Axios.delete(`/produit/produits/${id}`);
    setProduit((prevProduit) => prevProduit.filter((item) => item.id !== id));
    setModal(false);
  };
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");

  const toggle = (id) => {
    setModal(!modal);
    setId(id);
  };
  const closeModal = () => {
    setModal(!modal);
  };

  return (
    <div className="container">
      <div className="row row-a">
        <div
          className="col-lg-12 col-md-12 d-flex justify-content-between"
          style={{ backgroundColor: "#000000" }}
        >
          <h1 className="justify-content-start" style={{ color: "#FFFFFF" }}>
            Ajouter Produit
          </h1>
          <Link
            to="/gest-Produit/add-product"
            style={{ color: "inherit", marginTop: "1%" }}
          >
            <button className="btn-button btn-secondary-button-admin justify-content-end">
              Ajouter{" "}
            </button>
          </Link>
        </div>
        <div className="col-md-12 col-lg-12">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Image</th>
                <th scope="col">Sous Catégorie</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>{" "}
            <tbody>
              {produit.map((produit) => (
                <tr key={produit.uuid}>
                  <th>{produit.produitName}</th>
                  <td style={{ width: "30%" }}>
                    <img
                      src={"../../images/" + produit.thumbnail}
                      style={{ width: "20%" }}
                      alt="produit img"
                    />
                  </td>
                  <td>{produit.subcategories.subcatName}</td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => toggle(produit.id)}
                    >
                      Supprimer{" "}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: "#ffffff" }}
                      />{" "}
                    </button>
                    <Link
                      to={`/edit-produit/${produit.uuid}`}
                      style={{ color: "inherit" }}
                    >
                      {" "}
                      <button className="btn btn-info">
                        Modifier{" "}
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "#fff" }}
                        />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
              <Modal isOpen={modal} id={id} toggle={(e) => closeModal}>
                <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
                <ModalBody>Le Produit Sera Supprimée Définitivement</ModalBody>
                <ModalFooter>
                  <Button
                    color="btn btn-danger"
                    onClick={(e) => deleteProduit(id)}
                  >
                    Supprimer{" "}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ color: "#FFFFFF" }}
                    />{" "}
                  </Button>
                  <Button color="secondary" onClick={(e) => closeModal()}>
                    Annuler
                  </Button>
                </ModalFooter>
              </Modal>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GestProduit;
