import React, { useState, useEffect } from "react";
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";

function SubCatDetail({ match }) {
  const [subCat, setSubCat] = useState([]);
  useEffect(() => {
    Axios.get(`/frontend/subcat/${match.params.id}`)
      .then((res) => {
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [match.params.id]);
  return (
    <>
      <div className="container-pp" id="toppage">
        {subCat.map((item) => (
          <img
            key={item.uuid}
            src={"../../images/" + item.bannerimgPath}
            alt="Snow"
            className="banner-img"
          />
        ))}

        <div className="centered">
          <div className="box">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <>
        <section className="news-1" id="blog">
          <div className="news py-6">
            <div className="container py-md-9">
              <div className="blog-grids row mt-0 mt-lg-5 mt-md-5 mb-5">
                {subCat.map((subCat) => (
                  <>
                  
                    {subCat.produits.map((val) => (
                      <div
                        key={subCat.uuid}
                        className="col-lg-6 col-md-6 col-sm-12 mt-3"
                      >
                        
                        <div key={val.uuid} className="blog-grid" id="zoomIn">
                          <Link smooth to={{pathname:`/${val.produitName}/${val.uuid}`,hash:"toppage"}}>
                            <figure>
                              <img
                                src={"../../images/" + val.thumbnail}
                                className="img-fluid"
                                alt=""
                              />
                            </figure>
                          </Link>
                          <div className="bottom-left">
                            <h3>
                              <Link smooth to={{pathname:`/${val.produitName}/${val.uuid}`,hash:"toppage"}}>
                                {val.produitName}
                              </Link>{" "}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default SubCatDetail;
