import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEnvelope,
  faPhoneAlt,
  faFax
} from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
function Contact() {
  return (
    <section className="w3l-features-1">
      <div className="features ">
        <div className="container-fluid">
          <div className="row mx-lg-5 mx-md-5 mx-0">
            <div className="col-md-3 col-lg-3 col-12 ">
              <div className="float-lt feature-gd pt-4">
                <span aria-hidden="true">
                  <FontAwesomeIcon
                    icon={faHome}
                    size="2x"
                    style={{ color: "#F29400" }}
                  />
                </span>
                <h5>Adresse</h5>
              </div>
              <div className="icon-info">
                  
                  <p >
                    67, Avenue Alain Savary, Cité les Jardins II Bloc B, 1er
                    Étage, Appart B51 Tunis, 1082
                  </p>
                </div>
            </div>
            <div className="col-md-3 col-lg-3 col-12">
              <div className="float-mid feature-gd pt-4">
                <span aria-hidden="true">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="2x"
                    style={{ color: "#F29400" }}
                  />
                </span>
                <h5>E-mail</h5>
              </div>
              <div className="icon-info">
                  
                  <p >sst@gnet.tn </p>
                </div>
            </div>
            <div className="col-md-3 col-lg-3 col-12  ">
              <div className="float-rt feature-gd pt-4">
                <span aria-hidden="true">
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    size="2x"
                    style={{ color: "#F29400" }}
                  />
                </span>
                <h5>Téléphone</h5>
                
              </div>
              <div className="icon-info">
                  
                  <p >+216 71 847 160 </p>
                </div>
            </div>
             <div className="col-md-3 col-lg-3 col-12 ">
              <div className="float-lt feature-gd pt-4">
                <span aria-hidden="true">
                  <FontAwesomeIcon
                    icon={faFax}
                    size="2x"
                    style={{ color: "#F29400" }}
                  />
                </span>
                <h5>Fax</h5>
              </div>
              
              <div className="icon-info">
                  
                  <p >+216 71 846 035 </p>
                </div>
            </div>  
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
