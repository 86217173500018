import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
function EditProduit({ match }) {
  const history = useHistory();
  const [subCat, setSubCat] = useState([]);
  const [produit, setProduit] = useState({
    uuid: "",
    produitName: "",
    produitDisc: "",
    thumbnail: "",
    bannerimgPath: "",
    fichTechnique: "",
    brochure: "",
    subCategoryId: "",
  });
  const [img, setImg] = useState("");
  const [imgName, setImgName] = useState("Image de Produit");
  const [banner, setBanner] = useState("");
  const [bannerName, setBannerName] = useState(
    "Image de Couverture de Produit "
  );
  const [ficheTechnique, setFicheTechnique] = useState("");
  const [ficheTechniqueName, setFicheTechniqueName] = useState(
    "Fiche Technique"
  );
  const [brochure, setBrochure] = useState("");
  const [brochureName, setBrochureName] = useState("Brochure");
  const onChangeFicheTechnique = (e) => {
    setFicheTechnique(e.target.files[0]);
    setFicheTechniqueName(e.target.files[0].name);
  };
  const onChangeBrochure = (e) => {
    setBrochure(e.target.files[0]);
    setBrochureName(e.target.files[0].name);
  };
  const onChangeImg = (e) => {
    setImg(e.target.files[0]);
    setImgName(e.target.files[0].name);
  };
  const onChangeBanner = (e) => {
    setBanner(e.target.files[0]);
    setBannerName(e.target.files[0].name);
  };
  useEffect(() => {
    Axios.get("/produit/subcategory")
      .then((res) => {
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    Axios.get(`/produit/produit/${match.params.uuid}`)
      .then((res) => setProduit(res.data[0]))
      .catch((err) => console.log(err.message));
  }, [match.params.uuid]);
  const onChange = (e) => {
    setProduit({ ...produit, [e.target.name]: e.target.value });
  };
  const updateProduit = async (e) => {
    e.preventDefault();
    const updatedProduit = new FormData();
    //files
    updatedProduit.append("img", img);
    updatedProduit.append("banner", banner);
    updatedProduit.append("fichTechnique", ficheTechnique);
    updatedProduit.append("brochure", brochure);
    // data
    updatedProduit.append("uuid", produit.uuid);
    updatedProduit.append("produitName", produit.produitName);
    updatedProduit.append("produitDisc", produit.produitDisc);
    updatedProduit.append("thumbnail", produit.thumbnail);
    updatedProduit.append("bannerimgPath", produit.bannerimgPath);
    updatedProduit.append("nameFicheTechnique", produit.fichTechnique);
    updatedProduit.append("nameBrochure", produit.brochure);
    updatedProduit.append("subCategoryId", produit.subCategoryId);
    Axios.put(`/produit/produit/${match.params.uuid}`, updatedProduit)
      .then((res) => {
        console.log(res.data);
        history.push("/gest-Produit");
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Ajouter Produit </h1>
          <br />

          <form onSubmit={updateProduit} encType="multipart/form-data">
            <div className="form-group">
              <label for="category">Catégorie</label>
              <select
                id="category"
                className="form-control"
                name="subCategoryId"
                onChange={onChange}
                value={produit.subCategoryId}
              >
                <option disabled>Choisissez Catégorie</option>
                {subCat.map((subCat) => (
                  <option key={subCat.uuid} value={subCat.id}>
                    {subCat.category.catName} {subCat.subcatName}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="name">Nom de Produit</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                name="produitName"
                onChange={onChange}
                value={produit.produitName}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="name">Description de Produit</label>
              <textarea
                name="produitDisc"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                onChange={onChange}
                value={produit.produitDisc}
              />
            </div>
            <div className="custom-file mb-3 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                accept="image/png,image/jpeg"
                onChange={onChangeImg}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {imgName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                <img
                  src={`../../images/${produit.thumbnail}`}
                  style={{ width: "40%", height: "40%" }}
                  alt=""
                />
              </div>
            </div>
            <div className="custom-file mb-3 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                accept="image/png,image/jpeg"
                onChange={onChangeBanner}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {bannerName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                {produit.bannerimgPath ? (
                  <img
                    src={`../../images/${produit.bannerimgPath}`}
                    style={{ width: "40%", height: "40%" }}
                    alt=""
                  />
                ) : null}
              </div>
            </div>

            {/* fiche technique */}
            <div className="custom-file mb-4 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                accept=".pdf"
                onChange={onChangeFicheTechnique}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {ficheTechniqueName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                <h6>Fiche Technique</h6>
                <a href={`../../files/${produit.fichTechnique}`} target="blank">
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    style={{ width: "20%", height: "20%", color: "#FF0000" }}
                  />
                </a>
              </div>
            </div>
            {/* brochure */}
            <div className="custom-file mb-4 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                accept=".pdf"
                onChange={onChangeBrochure}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {brochureName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                <h6>Brochure</h6>
                <a href={`../../files/${produit.brochure}`} target="blank">
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    style={{ width: "20%", height: "20%", color: "#FF0000" }}
                  />
                </a>
              </div>
            </div>

            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Modifier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditProduit;
