import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faPhoneAlt, faFax } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Message from "./user/Message";
import Axios from "axios";
const schema = yup.object().shape({
    name: yup.string().required("ce champ est obligatoire"),
    email: yup
      .string()
      .email("l'email doit être un email valide")
      .required("ce champ est obligatoire"),
    msg: yup.string().required("ce champ est obligatoire"),
    sujet: yup.string().required("ce champ est obligatoire"),
  });
function ContactezNous() {
    const history = useHistory();
    const [message, setMessage] = useState("");
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmit = async (data, e) => {
        e.preventDefault();
        const newEmail = new FormData();
        newEmail.append("name", data.name);
        newEmail.append("email", data.email);
        newEmail.append("sujet", data.sujet);
        newEmail.append("msg", data.msg); 
        try {
          await Axios.post("/frontend/new-email", newEmail)
    
            .then((res) => {
              const { message } = res.data;
              setMessage(message);
              if (!message.msgError) {
                setTimeout(() => document.getElementById("myform").reset(), 1100);
                setTimeout(() => history.push("/"), 1300);
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } catch (err) {
          if (err.response.status === 500) {
            setMessage("There was a problem with the server");
          } else {
            setMessage(err.response.data.msg);
          }
        }
      };
    return (
        <div className="container" id="toppage" >
            <div className="row row-pcn"  >
                <div className="col-md-6 col-12 col-lg-6 mt-5 pt-3">
                    <h1>Contactez Nous </h1>
                    <p><a href="tel:+216 71 847 160" style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faPhoneAlt} size="1x" style={{color:"#F29400"}}  /></span> +216 71 847 160</a></p>
                    <p><span  aria-hidden="true"><FontAwesomeIcon icon={faFax} size="1x" style={{color:"#F29400"}}  /></span> +216 71 846 035</p>
                    <p><a href="mailto:sst@gnet.tn"  style={{color:"inherit"}}><span  aria-hidden="true"><FontAwesomeIcon icon={faEnvelope} size="1x" style={{color:"#F29400"}}  /></span> sst@gnet.tn</a></p>
                   
                   
                    {message ? <Message message={message} /> : null}
                    <form onSubmit={handleSubmit(onSubmit)} id="myform">
                    <div className="form-group">
             <label htmlFor="nom">Votre nom complet</label>
              <input 
              type="text" 
              className={`${
                errors.name ? `form-control is-invalid` : `form-control`
              }`}
              id="nom" 
              aria-describedby="nom"
              name="name"
                placeholder="Votre Nom..."
                ref={register}

              />
              <div className="error ">{errors.name?.message}</div>
              </div>
           <div className="form-group">
             <label htmlFor="exampleInputEmail1">Votre Courriel</label>
              <input 
               type="email"
               className={`${
                 errors.email ? `form-control is-invalid` : `form-control`
               }`}
               id="exampleInputEmail1"
               aria-describedby="emailHelp"
               placeholder="Votre Courriel..."
               name="email"
               ref={register}
            
              />
              <div className="error ">{errors.email?.message}</div>
               <small id="emailHelp" className="form-text text-muted">Nous ne partageons jamais votre e-mail avec qui que ce soit</small>
              </div>
              <div className="form-group">
             <label htmlFor="sujet">Sujet</label>
              <input 
              type="text"
              className={`${
                errors.email ? `form-control is-invalid` : `form-control`
              }`}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Sujet..."
              name="sujet"
              ref={register}
              />
              </div>
              <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                placeholder="votre Message"
                className={`${
                  errors.msg ? `form-control is-invalid` : `form-control`
                }`}
                id="message"
                rows="6"
                name="msg"
                ref={register}
              ></textarea>
              <div className="error ">{errors.msg?.message}</div>
              </div>
  
  <button type="submit" className="btn-button btn-secondary-button ">Envoyer</button>
 
</form>
                </div>
                <div className="col-md-6 col-12 col-lg-6 mt-5 pt-3">
                <iframe
                loading="lazy"
                allowFullScreen
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1129.091862746374!2d10.187797617860639!3d36.829204784919504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd3568b81efb3d%3A0xccace99e724f494e!2sSST%20-%20SOKKIA!5e0!3m2!1sen!2stn!4v1619771057668!5m2!1sen!2stn" 
                 width="100%" height="350px" title="map"></iframe>
                 
                </div>
            </div>
            
        </div>
    )
}

export default ContactezNous
