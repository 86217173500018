import React, { useState } from "react";
import Axios from "axios";
import Message from "./Message";
import Progress from "./Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  carousselImg: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg", (value) => {
      return value && value[0].type === "image/jpeg";
    }),
  lien: yup.string().required("ce champ est obligatoire"),
});
function AddCaroussel() {
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data, e) => {
    console.log(data);

    e.preventDefault();
    const newCaroussel = new FormData();
    newCaroussel.append("carousselImg", data.carousselImg[0]);
    newCaroussel.append("lien", data.lien);
    newCaroussel.append("title", data.title);
    newCaroussel.append("description", data.disc);

    try {
      await Axios.post("/produit/caroussel", newCaroussel, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );

          // Clear form
          setTimeout(() => setUploadPercentage(0), 1000);
          setTimeout(() => setMessage(""), 1000);
          setTimeout(() => history.push("/gest-caroussel"), 1050);
        },
      });

      setMessage("File Uploaded");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };

  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Ajouter Image a Caroussel </h1>
          <br />
          {message ? <Message msg={message} /> : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            method="post"
          >
            <div className="form-group">
              <label htmlFor="title">Titre</label>
              <input
                type="text"
                className={`${
                  errors.title ? `form-control is-invalid` : `form-control`
                }`}
                name="title"
                id="title"
                ref={register}
              />
              <div className="error ">{errors.title?.message}</div>
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className={`${
                  errors.disc ? `form-control is-invalid` : `form-control`
                }`}
                id="description"
                aria-describedby="emailHelp"
                name="disc"
                ref={register}
              />
              <div className="error ">{errors.disc?.message}</div>
            </div>
            <div className="form-group">
              <label htmlFor="lien">Lien</label>
              <select
                className={`${
                  errors.lien ? `form-control is-invalid` : `form-control`
                }`}
                id="lien"
                aria-describedby="emailHelp"
                name="lien"
                ref={register}
              >
                <option value="/produit">Produit Page</option>
                <option value="/service">Service Page</option>
                <option value="/a-propos">A propos Page</option>
                <option value="/contactez-nous">Contactez Nous Page</option>
              </select>
              <div className="error ">{errors.lien?.message}</div>
            </div>

            <div className="form-group mb-4">
              <label>Image</label>
              <input
                type="file"
                className={`${
                  errors.carousselImg
                    ? `form-control is-invalid`
                    : `form-control`
                }`}
                id="customFile"
                ref={register}
                name="carousselImg"
              />
              <div className="error ">{errors.carousselImg?.message}</div>
            </div>
            <Progress percentage={uploadPercentage} />
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCaroussel;
