import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

function SearchBar({isOpen,setIsOpen}) {
  const { register, handleSubmit, reset } = useForm({});
  const history = useHistory();
  
  const onSubmit = async (data, e) => {
    e.preventDefault();
    history.push(`/sst/search/${data.word}`);
    setIsOpen(!isOpen)
    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-inline mr-5 mt-2">
        <input
           
          className="form-control search "
          placeholder="Chercher..."
          style={{ color: "#fff" }}
          ref={register}
          name="word"
        />
        <button
          className="btn btn-search position-absolute mr-5 "
          type="submit"
           
        >
          <span aria-hidden="true">
            <FontAwesomeIcon icon={faSearch} style={{ color: "#F29400" }} />
          </span>
        </button>
      </div>
    </form>
 
  );
}

export default SearchBar;
