import React from "react";

import { useAuth0 } from '@auth0/auth0-react';

const SingIn=()=>{
 
    const { loginWithRedirect } = useAuth0();
    
    
     
    return <button onClick={() => loginWithRedirect()} 
                   style={{marginTop:"30%",marginLeft:"50%",marginBottom:"50%"}}>
                   Log In
           </button>
     
    
};

export default SingIn
