import React, { useState, useEffect } from "react";
import Axios from "axios";
function Propos() {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const page = new FormData();
    page.append("link", "/a-propos");
    Axios.post("/frontend/banner", page)
      .then((res) => setBanner(res.data))
      .catch((err) => console.log(err.message));
  }, []);
  return (
    <>
      <div className="container-pp" id="toppage">
        {banner.map((item) => (
          <img
            key={item.uuid}
            src={`../../images/${item.imgPath}`}
            alt="Snow"
            className="banner-img"
          />
        ))}
        <div className="centered">
          <div className="box">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div id="presentation" className="mt-md-3 mt-lg-5 mt-3">
          <h1>Présentation</h1>
          
          <p className="lead">
            Surveying Systems Tunisia , distributeur exclusif de la marque
            Sokkia & Topcon pour la vente et le service après-vente de matériels
            de topographie , de construction et de gestion de chantier, ainsi
            que des logiciels, dédiés à l’accroissement de la productivité de
            vos chantiers.
          </p>
        </div>
        <div id="historique">
          <h1 className="mt-5">Historique </h1>
           
          <p className="lead">
            Fondée en 1996, Surveying Systems Tunisia se spécialise depuis sa
            création dans la distribution d’instruments reliés à la topographie
            et à la construction. Entièrement dédiée à sa clientèle et se
            donnant pour mission de toujours lui offrir un service incomparable,
            l’organisation se fait un point d’honneur de se renouveler
            constamment. De cette manière, S.S.T est en mesure de proposer à ses
            clients les solutions les plus évoluées provenant des avancées
            technologiques de son manufacturier, SOKKIA & TOPCON. Forte d’un
            centre d’entretien et de réparation certifié par le manufacturier,
            d’une équipe technique, d’un réseau de distribution pour toutes la
            Tunisie, d’accessoires et de fournitures. S.S.T se positionne comme
            un partenaire de choix pour votre entreprise toujours en quête
            d’efficience.
          </p>
        </div>
        <div id="partenaire" className="container">
          <h1 className="mt-5">Partenaires</h1>
          <div className="row">
            <div className="col-6 col-md-6">
              <img
                src="../images/sokkia-logo.jpg"
                id="sokkia-topcon"
                alt="sokkia"
              />
            </div>
            <div className="col-6  col-md-6">
              <img
                src="../images/topcon-logo2-01.jpg"
                id="sokkia-topcon"
                alt="topcon"
              />
            </div>
          </div>
        </div>
        <div id="clients" className="">
          <h1>Nos Clients</h1>
          
          <div className="container-pp ml-3">
            <div className="row ml-3">
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/afh.png"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/otc.jpg"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/afa.png"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/CNCT.png"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/logo_atfp.jpg"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>

              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/130920518_222665552555209_5384910908193944472_n-removebg-preview.png"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/4JPYhki.jpeg"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/92104328_868270233645044_7221292865983348736_n.png"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/bonna-01.png"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
              <div className="col-md-2 col-5 ml-2">
                {" "}
                <img
                  src="../images/130485908_3531437656893798_8723934714446286556_n.jpg"
                  style={{ width: "100%", height: "75%" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
}

export default Propos;
