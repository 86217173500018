import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function GestCategory() {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");

  const toggle = (id) => {
    setModal(!modal);
    setId(id);
  };
  const closeModal = () => {
    setModal(!modal);
  };
  //load data from db
  const [cat, setCat] = useState([]);
  useEffect(() => {
    Axios.get("/produit/category")
      .then((res) => {
        setCat(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  const deleteCat = async (id, e) => {
    await Axios.delete(`/produit/category/${id}`);
    setCat((prevCat) => prevCat.filter((val) => val.id !== id));
    setModal(false);
  };

  return (
    <div className="container">
      <div className="row row-a">
        <div
          className="col-lg-12 col-md-12 d-flex justify-content-between"
          style={{ backgroundColor: "#000000" }}
        >
          <h1 className="justify-content-start" style={{ color: "#FFFFFF" }}>
            Ajouter Catégorie
          </h1>
          <HashLink
            to="/gest-cat/add-cat"
            style={{ color: "inherit", marginTop: "1%" }}
          >
            <button className="btn-button btn-secondary-button-admin justify-content-end">
              Ajouter{" "}
            </button>
          </HashLink>
        </div>
        <div className="col-lg-12 col-md-12 ">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cat.map((cat) => (
                <tr key={cat.uuid}>
                  <th>{cat.catName}</th>
                  <td style={{ width: "30%" }}>
                    <img
                      src={"../../images/" + cat.imgPath}
                      style={{ width: "20%" }}
                      alt="category img"
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => toggle(cat.id)}
                    >
                      Supprimer{" "}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: "#FFFFFF" }}
                      />{" "}
                    </button>{" "}
                    <HashLink
                      to={`/edit-category/${cat.uuid}`}
                      style={{ color: "inherit" }}
                    >
                      {" "}
                      <button className="btn btn-info">
                        Modifier{" "}
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: "#fff" }}
                        />{" "}
                      </button>
                    </HashLink>{" "}
                  </td>
                </tr>
              ))}
              <Modal isOpen={modal} id={id} toggle={(e) => closeModal}>
                <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
                <ModalBody>
                  La Catégorie Sera Supprimée Définitivement
                </ModalBody>
                <ModalFooter>
                  <Button color="btn btn-danger" onClick={(e) => deleteCat(id)}>
                    Supprimer{" "}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ color: "#FFFFFF" }}
                    />{" "}
                  </Button>
                  <Button color="secondary" onClick={(e) => closeModal()}>
                    Annuler
                  </Button>
                </ModalFooter>
              </Modal>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GestCategory;
