import React from "react";
import { Col, Container } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

function AboutUs() {
  return (
    <div className="w3l-about1 py-5" id="about">
      <Container className="container py-lg-3">
        <div className="aboutgrids row ">
           
          <Col className="col-lg-8 col-lg-8 col-12 aboutgrid1 mt-lg-0 mt-4 pl-lg-5 text-center  mx-auto">
            <h4 className="text-center">À Propos</h4>
            <p className="text-left">
              {" "}
              Surveying Systems Tunisia , distributeur exclusif de la marque
              Sokkia & Topcon pour la vente et le service après-vente de
              matériels de topographie , de construction et de gestion de
              chantier, ainsi que des logiciels, dédiés à l’accroissement de la
              productivité de vos chantiers.{" "}
            </p>

            <Link
              smooth
              to={{ pathname: "/a-propos", hash: "toppage" }}
              className="btn-button btn-secondary-button"
            >
              {" "}
              Voir plus
            </Link>
          </Col>
        </div>
      </Container>
    </div>
  );
}

export default AboutUs;
