import React from "react";
import ContactezNous from "./components/ContactezNous";
import Navigation from "./components/Navigation";
import Produit from "./components/Produit";
import Propos from "./components/Propos";
import Service from "./components/Service";
import Accueil from "./components/Accueil";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import SingIn from "./components/user/SingIn";
import UserNav from "./components/user/UserNav";
import { useAuth0 } from "@auth0/auth0-react";
import GestCategory from "./components/user/GestCategory";
import GestSubCategory from "./components/user/GestSubCategory";
import GestProduit from "./components/user/GestProduit";
import GestCaroussel from "./components/user/GestCaroussel";
import GestImages from "./components/user/GestImages";
import AddCategory from "./components/user/AddCategory";
import AddSubCat from "./components/user/AddSubCat";
import AddProduct from "./components/user/AddProduct";
import AddImages from "./components/user/AddImages";
import AddCaroussel from "./components/user/AddCaroussel";
import GestBanner from "./components/user/GestBanner";
import AddBanner from "./components/user/AddBanner";
import SubCatDetail from "./components/SubCatDetail";
import CatDetail from "./components/CatDetail";
import ProduitsDetail from "./components/ProduitsDetail";
import NotMatch from "./components/NotMatch";
import EditCat from "./components/user/EditCat";
import EditSubCat from "./components/user/EditSubCat";
import EditProduit from "./components/user/EditProduit";
import EditCarousel from "./components/user/EditCarousel";
import EditBanner from "./components/user/EditBanner";
import FooterUser from "./components/user/FooterUser";
import SearchResult from "./components/SearchResult";

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <UserNav />
          <Switch>
            <Route path="/gest-cat" exact component={GestCategory} />
            <Route
              path="/gest-Sub-Category"
              exact
              component={GestSubCategory}
            />
            <Route path="/gest-Produit" exact component={GestProduit} />
            <Route path="/gest-images" exact component={GestImages} />
            <Route path="/gest-caroussel" exact component={GestCaroussel} />
            <Route path="/gest-banner" exact component={GestBanner} />
            <Route path="/gest-cat/add-cat" exact component={AddCategory} />
            <Route path="/gest-Sub-Category/add-subcat" component={AddSubCat} />
            <Route path="/gest-Produit/add-product" component={AddProduct} />
            <Route path="/gest-images/add-images" component={AddImages} />
            <Route
              path="/gest-caroussel/add-caroussel"
              component={AddCaroussel}
            />
            <Route path="/gest-banner/add-banner" component={AddBanner} />
            <Route path="/edit-category/:uuid" component={EditCat} />
            <Route path="/edit-subcategory/:uuid" component={EditSubCat} />
            <Route path="/edit-produit/:uuid" component={EditProduit} />
            <Route path="/edit-carousel/:uuid" component={EditCarousel} />
            <Route path="/edit-banner/:uuid" component={EditBanner} />
          </Switch>
          <FooterUser />
        </>
      ) : (
        <div className="App">
          <Navigation />
          <Switch>
            <Route path="/" exact component={Accueil} />
            <Route path="/produit" exact component={Produit} />
            <Route path="/service" component={Service} />
            <Route path="/a-propos" component={Propos} />
            <Route path="/contactez-nous" component={ContactezNous} />
            <Route path="/produit/:name/:id" component={SubCatDetail} />
            <Route path="/singin/admin2863prye4" exact component={SingIn} />
            <Route path="/brand/:name/:id" component={CatDetail} />
            <Route path="/:name/:uuid" exact component={ProduitsDetail} />

            <Route path="/sst/search/:word" exact component={SearchResult} />
            <Route path="*">
              <NotMatch />
            </Route>
          </Switch>
          <Footer />
        </div>
      )}
    </Router>
  );
}

export default App;
