import React, { useState } from "react";
import Axios from "axios";
import Message from "./Message";
import Progress from "./Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const schema = yup.object().shape({
  bannerImg: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (
        (value && value[0].type === "image/jpeg") ||
        (value && value[0].type === "image/png")
      );
    }),
  lien: yup
    .string()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être unique", (value) => {
      return (
        value &&
        value !== "/produit" &&
        value &&
        value !== "/service" &&
        value &&
        value !== "/a-propos"
      );
    }),
});
function AddBanner() {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const newBanner = new FormData();
    newBanner.append("bannerImg", data.bannerImg[0]);
    newBanner.append("lien", data.lien);

    try {
      await Axios.post("/produit/banner", newBanner, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );

          // Clear form
          setTimeout(() => setUploadPercentage(0), 1000);
          setTimeout(() => setMessage(""), 1000);
          setTimeout(() => history.push("/gest-banner"), 1050);
        },
      });

      setMessage("File Uploaded");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };

  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Ajouter Image De Couverture </h1>
          <br />
          {message ? <Message msg={message} /> : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            method="post"
          >
            <div className="form-group">
              <label htmlFor="lien">Page</label>
              <select
                className={`${
                  errors.lien ? `form-control is-invalid` : `form-control`
                }`}
                id="lien"
                aria-describedby="emailHelp"
                name="lien"
                ref={register}
              >
                <option selected value="/produit">
                  Produit Page
                </option>
                <option value="/service">Service Page</option>
                <option value="/a-propos">A propos Page</option>
              </select>
              <div className="error ">{errors.lien?.message}</div>
            </div>

            <div className="form-group mb-4">
              <label>Image</label>
              <input
                type="file"
                className={`${
                  errors.bannerImg ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                ref={register}
                name="bannerImg"
              />

              <div className="error ">{errors.bannerImg?.message}</div>
            </div>
            <Progress percentage={uploadPercentage} />
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBanner;
