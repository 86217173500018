import React,{useState} from 'react';
import Axios from 'axios';
import Message from "./user/Message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
const schema = yup.object().shape({
    email: yup
      .string()
      .email("l'email doit être un email valide")
      .required("ce champ est obligatoire"),
  });
function NewsLetter() {
    const history = useHistory();
    const [message, setMessage] = useState("");
    const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmit = async (data, e) => {
        e.preventDefault();
        const emailSent = new FormData(); 
        emailSent.append("email", data.email); 
        try {
          await Axios.post("/frontend/newsletter", emailSent)
            .then((res) => {
              const { message } = res.data;
              setMessage(message);
              if (!message.msgError) {
                setTimeout(() => document.getElementById("form").reset(), 1100);
                setTimeout(() => history.push("/"), 1300);
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        } catch (err) {
          if (err.response.status === 500) {
            setMessage("There was a problem with the server");
          } else {
            setMessage(err.response.data.msg);
          }
        }
      };
    return (
        <div className="grids-forms">
        <div className="main-midd">
            <h4 className="title-head">InfoLettre – Gardez à Jour Les Dernières Nouvelles</h4>
            
        </div>
        <div className="main-midd-2">
        {message ? <Message message={message} /> : null}
        
            <form onSubmit={handleSubmit(onSubmit)} className="rightside-form" id="form">
                <input 
                placeholder="Entrer votre email"
                 type="email"
                className={`${
                  errors.email ? `is-invalid` : ``
                }`}
                id="exampleInputEmail1"
                aria-describedby="emailHelp" 
                name="email"
                ref={register}
                 />
                 
                <button className="btn" type="submit">S'abonner</button>
                
            </form>  
             <div className="error ">{errors.email?.message}</div>
        </div>
     
      </div>
    )
}

export default NewsLetter
