import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faInstagram,faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from 'react-router-hash-link';
import NewsLetter from "./NewsLetter";
function topFunction() {
           document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
}
function Footer() {
 
    return (
        


 
    <section className="w3l-footer-29-main" id="footer" style={{backgroundImage:"url(../../images/3.jpg)",backgroundRepeat:"no-repeat",backgroundPosition:"center",}}>
    <div className="footer-29">
      <div className="grids-forms-1 pb-5">
  <div className="container">
    <NewsLetter/>
    </div>
    </div>
        <div className="container pt-5">
           
            <div className="row  ">
                <div className="footer-list-29 col-md-6 col-lg-6 footer-1">
                    <h6 className="footer-title-29">À Propos</h6>
                    <ul>
                       <p>S.S.T est le distributeur des instruments de topographie du SOKKIA - TOPCON pour les secteurs de l'arpentage et de la construction</p>
                    </ul>
                    <div className="main-social-footer-29">
                      <h6 className="footer-title-29">Liens Sociaux</h6>
                        <a href="//www.facebook.com/Surveying-Systems-Tunisia-100535075213915" target="_blank" className="facebook"><span ><FontAwesomeIcon  icon={faFacebook}/></span></a>
                        <a href="//www.instagram.com/surveying_systems_tunisia/?fbclid=IwAR0c1QsDOjWCfzAH3VOnjUKCQ0v186kbUiyVustAe50LOkqQEdHVw6xJHVo" target="_blank" className="instagram"><span><FontAwesomeIcon icon={faInstagram}/></span></a>
                        <a href="//www.linkedin.com/company/surveying-systems-tunisia/" target="_blank" className="linkedin"><span><FontAwesomeIcon  icon={faLinkedinIn}/></span></a>
                    </div>
                    <br/>
                </div>
               
                <div className="footer-list-29 col-md-3 col-lg-3 col-4 footer-2">
                    <ul>
                        <h6 className="footer-title-29">Liens Utiles</h6>
                        <li><Link smooth to={{pathname:"/produit",hash:"toppage"}}>Produits</Link></li>
                        <li><Link smooth to={{pathname:"/Service",hash:"toppage"}}>Services</Link></li>
                        <li><Link smooth to={{pathname:"/a-propos",hash:"toppage"}}>A Propos</Link></li>
                        <li><Link smooth to={{pathname:"/contactez-nous",hash:"toppage"}}>Contactez-Nous</Link></li> 
                    </ul>
                </div>
                <div className="footer-list-29 col-md-3 col-lg-3 col-8 footer-3">
                <h6 className="footer-title-29">Carte</h6>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1129.091862746374!2d10.187797617860639!3d36.829204784919504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd3568b81efb3d%3A0xccace99e724f494e!2sSST%20-%20SOKKIA!5e0!3m2!1sen!2stn!4v1619771057668!5m2!1sen!2stn"
                 style={{width:"100%",height:"80%"}}
                 allowFullScreen 
                  loading="lazy"></iframe>

                </div>
                
            </div>
            
            </div>
            <div className="bottom-copies text-center">
                <p className="copy-footer-29">Copyright © S.S.T {new Date().getFullYear()} - Propulsé par <a href="https://www.seek4digital.com/" target="_blank">Seek4Digital</a>.</p>
                 
            </div>
        
    </div>
     
    <button onClick={topFunction} id="movetop" title="Go to top" ><span ><FontAwesomeIcon  icon={faAngleDoubleUp}/></span></button>
  </section>          

 
    )
}

export default Footer
