import React, { useState, useEffect } from "react";
import Axios from "axios";
import Message from "./Message";
import Progress from "./Progress";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const schema = yup.object().shape({
  thumbnail: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être image de type jpeg or png", (value) => {
      return (
        (value && value[0].type === "image/jpeg") ||
        (value && value[0].type === "image/png")
      );
    }),

  ficheTechnique: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être fichier pdf", (value) => {
      return value && value[0].type === "application/pdf";
    }),
  brochure: yup
    .mixed()
    .required("ce champ est obligatoire")
    .test("type", "ce champ doit être fichier pdf", (value) => {
      return value && value[0].type === "application/pdf";
    }),
  idSousCategory: yup.string().required("ce champ est obligatoire"),
  nameProd: yup.string().required("ce champ est obligatoire"),
  description: yup.string().required("ce champ est obligatoire"),
});
function AddProduct() {
  const [subCat, setSubCat] = useState([]);
  const history = useHistory();
  useEffect(() => {
    Axios.get("/produit/subcategory")
      .then((res) => {
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  //save data
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const newProd = new FormData();
    newProd.append("idSousCategory", data.idSousCategory);
    newProd.append("nameProd", data.nameProd);
    newProd.append("thumbnail", data.thumbnail[0]);
    newProd.append("description", data.description);
    newProd.append("ficheTechnique", data.ficheTechnique[0]);
    newProd.append("brochure", data.brochure[0]);
    newProd.append("banner", data.banner[0]);

    try {
      await Axios.post("/produit/produit", newProd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );

          // Clear form
          setTimeout(() => setUploadPercentage(0), 2000);
          setTimeout(() => setMessage(""), 1000);
          setTimeout(() => history.push("/gest-Produit"), 2050);
        },
      });

      setMessage("File Uploaded");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Ajouter Produit </h1>
          <br />
          {message ? <Message msg={message} /> : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            method="post"
          >
            <div className="form-group">
              <label for="category">Catégorie</label>
              <select
                id="category"
                className={`${
                  errors.idSousCategory
                    ? `form-control is-invalid`
                    : `form-control`
                }`}
                ref={register}
                name="idSousCategory"
              >
                <option disabled>Choisissez Catégorie</option>
                {subCat.map((subCat) => (
                  <option key={subCat.uuid} value={subCat.id}>
                    {subCat.category.catName} {subCat.subcatName}
                  </option>
                ))}
              </select>
              <div className="error ">{errors.idSousCategory?.message}</div>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="name">Nom de Produit</label>
              <input
                type="text"
                name="nameProd"
                className={`${
                  errors.nameProd ? `form-control is-invalid` : `form-control`
                }`}
                id="name"
                aria-describedby="emailHelp"
                ref={register}
              />
              <div className="error ">{errors.nameProd?.message}</div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="name">Description de Produit</label>
              <textarea
                name="description"
                className={`${
                  errors.description
                    ? `form-control is-invalid`
                    : `form-control`
                }`}
                id="name"
                aria-describedby="emailHelp"
                ref={register}
              />
              <div className="error ">{errors.description?.message}</div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="name">Image</label>
              <input
                type="file"
                className={`${
                  errors.thumbnail ? `form-control is-invalid` : `form-control`
                }`}
                name="thumbnail"
                id="customFile"
                accept="image/png,image/jpeg"
                ref={register}
              />
              <div className="error ">{errors.thumbnail?.message}</div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="name">Image de Couverture</label>
              <input
                type="file"
                className={`${
                  errors.banner ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                accept="image/png,image/jpeg"
                name="banner"
                ref={register}
              />
              <div className="error ">{errors.banner?.message}</div>
            </div>

            {/* fiche technique */}
            <div className="form-group mb-4">
              <label>Fiche Technique</label>
              <input
                type="file"
                className={`${
                  errors.ficheTechnique
                    ? `form-control is-invalid`
                    : `form-control`
                }`}
                id="customFile"
                accept=".pdf"
                ref={register}
                name="ficheTechnique"
              />
              <div className="error ">{errors.ficheTechnique?.message}</div>
            </div>
            {/* brochure */}
            <div className="form-group mb-4">
              <label>Brochure</label>
              <input
                type="file"
                className={`${
                  errors.brochure ? `form-control is-invalid` : `form-control`
                }`}
                id="customFile"
                accept=".pdf"
                ref={register}
                name="brochure"
              />
              <div className="error ">{errors.brochure?.message}</div>
            </div>
            <Progress percentage={uploadPercentage} />
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
