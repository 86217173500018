import React, { useState, useEffect } from "react";
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";

function SearchResult({ match }) {
  const [result, setResult] = useState([]);
  useEffect(() => {
    Axios.get(`/frontend/search/${match.params.word}`)
      .then((res) => setResult(res.data))
      .catch((err) => console.log(err.message));
  }, [match.params.word]);
  return (
    <section className="news-1" id="blog">
      <div className="news py-5">
        <div className="container mt-5">
          <div className="blog-grids row  mb-5">
            {result.length !== 0 ? (
              result.map((val, key) => (
                <div key={val.uuid} className="col-lg-4 col-md-4 col-12 my-5">
                  <div key={key} className="blog-grid" id="zoomIn">
                    <figure>
                      <Link to={`/${val.produitName}/${val.uuid}`}>
                        {" "}
                        <img
                          src={"../../images/" + val.thumbnail}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </figure>

                    <div className="bottom-left">
                      <h3>
                        <Link to={`/${val.produitName}/${val.uuid}`}>
                          {val.produitName}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 col-md-12 col-lg-12">
                <h1 className="text-center my-5">
                  {" "}
                  Aucun Résultat Trouvé Pour "{match.params.word}"{" "}
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchResult;
