import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from "react-router-hash-link";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function GestImages() {
  //load Data from db
  const [images, setImages] = useState([]);

  useEffect(() => {
    Axios.get("/produit/images")
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // delete Data from db
  const deleteImage = async (id, e) => {
    await Axios.delete(`/produit/images/${id}`);
    setImages((prevImages) => prevImages.filter((item) => item.id !== id));
    setModal(false);
  };
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");

  const toggle = (id) => {
    setModal(!modal);
    setId(id);
  };
  const closeModal = () => {
    setModal(!modal);
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div
          className="col-lg-12 col-md-12 d-flex justify-content-between"
          style={{ backgroundColor: "#000000" }}
        >
          <h1 className="justify-content-start" style={{ color: "#FFFFFF" }}>
            Ajouter des images
          </h1>
          <Link to="/gest-images/add-images" style={{ marginTop: "1%" }}>
            <button className="btn-button btn-secondary-button-admin justify-content-end">
              Ajouter
            </button>
          </Link>
        </div>
        <div className="col-lg-12 col-md-12 ">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Produit Name</th>
                <th scope="col">Image</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {images.map((image) => (
                <tr key={image.id}>
                  <th>{image.produits.produitName}</th>
                  <td style={{ width: "30%" }}>
                    <img
                      src={"../../images/" + image.pathImage}
                      style={{ width: "20%" }}
                      alt="produit img"
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => toggle(image.id)}
                    >
                      Supprimer{" "}
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: "#FFFFFF" }}
                      />{" "}
                    </button>{" "}
                  </td>
                </tr>
              ))}
              <Modal isOpen={modal} id={id} toggle={(e) => closeModal}>
                <ModalHeader toggle={closeModal}>Vous êtes sûr ? </ModalHeader>
                <ModalBody>
                  L'image du Produit Sera Supprimée Définitivement
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="btn btn-danger"
                    onClick={(e) => deleteImage(id)}
                  >
                    Supprimer{" "}
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ color: "#FFFFFF" }}
                    />{" "}
                  </Button>
                  <Button color="secondary" onClick={(e) => closeModal()}>
                    Annuler
                  </Button>
                </ModalFooter>
              </Modal>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GestImages;
