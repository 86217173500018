import React, { useEffect, useState,Fragment } from "react";
import Axios from "axios";

import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../App.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCollapse,
} from "reactstrap";
import { ReactComponent as CaretIcon } from "../icons/caret.svg";
import SearchBar from "./SearchBar";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const[showOne,setShowOne]=useState(false);
  const dropOne=()=>{
    
      setShowOne(!showOne)
    
  }
  const[showTwo,setShowTwo]=useState(false);
  const dropTwo=()=>{
    
      setShowTwo(!showTwo)
    
  }
  const[showThree,setShowThree]=useState(false);
  const dropThree=()=>{
    
      setShowThree(!showThree)
    
  }
  const[showFour, setShowFour]=useState(false);
  const dropFour=()=>{setShowFour(!showFour)}
  
  const toggle = () => setIsOpen(!isOpen);
  //load data from db

  const [subCat, setSubCat] = useState([]);
  useEffect(() => {
    Axios.get("/produit/nav")
      .then((res) => {
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // const[dropdown, setDropdown]=useState(false);
  // const active=()=>{setDropdown(!dropdown)}
  
  return (
    <section className="w3l-bootstrap-header">
      <Navbar expand="lg">
          <HashLink smooth to={{ pathname: "/", hash: "toppage" }}>
          <NavbarBrand rel="noreferrer">
            <div>
              <img
                src="../../images/0.png"
                title="SST logo"
                alt="SST logo"
                className="logo-sst mr-5 pr-5"
                style={{ marginLeft: "15%" }}
              />
            </div>
          </NavbarBrand>
        </HashLink>  

        <NavbarToggler onClick={toggle} className={`${isOpen ? "togler-custom" : " "}`} />
        <Collapse
          isOpen={isOpen}
          navbar
          className="collapse navbar-collapse"
          id="navbarSupportedContent"  
        >
          <Nav navbar className="navbar-nav mx-auto" expand="lg">
            <NavItem className="nav-item  mr-3 mt-2" >
              <NavLink
                exact
                to={{ pathname: "/", hash: "toppage" }}
                className="nav-link-test mr-1 text-uppercase"
                activeClassName="on  "
               onClick={toggle}
              >
                Accueil
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className="nav-item mr-3 mt-2 "  onMouseEnter={dropOne} onMouseLeave={dropOne}>
              <div className="d-flex justify-between"   >
                <NavLink
                  to="/produit#toppage"
                  className="nav-link-test mr-1 text-uppercase"
                  activeClassName=" on  "
                  onClick={isOpen ? toggle : null}
                >
                  Produits
                </NavLink>
                <DropdownToggle nav  >  <CaretIcon  style={{ width: "7px",fill:"white" }} className="dropdown-toggle toggle-custom" /> </DropdownToggle>
              </div>

              <DropdownMenu  className={`${isOpen ? "dropdown-menu-no " : "dropdown-menu-yes"} ${showOne ? "show" : ""}`}  >
                {subCat.map((item,key) => (
                  <Fragment key={key}  >
                    <div
                      className="dropdown-item  "
                      id={item.catName.replace(" ", "")}
                      style={{ cursor: "pointer" }}
                    >
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: `/brand/${item.catName}/${item.id}`,
                          hash: "toppage",
                        }}
                        className="dropdown-item text-uppercase"
                        activeClassName=" on "
                      >
                        {item.catName} <CaretIcon style={{ width: "7px" }} className="ml-2" />
                      </NavLink>
                    </div>

                    <UncontrolledCollapse
                      toggler={`#${item.catName.replace(" ", "")}`}
                    >
                      {item.subcategories.map((val) => (
                        <a
                          href={`/produit/${val.subcatName}/${val.id}`}
                          className="dropdown-item ml-3 text-uppercase"
                          key={val.uuid}
                          
                        >
                          {val.subcatName} 
                        </a>
                      ))}
                    </UncontrolledCollapse>
                  </Fragment>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="nav-item mr-3   mt-2  "  onMouseEnter={dropTwo} onMouseLeave={dropTwo}>
               <div className="d-flex justify-between">
                <NavLink
                  to={{ pathname: "/service", hash: "toppage" }}
                  className="nav-link-test mr-1 text-uppercase"
                  activeClassName="on "
                  onClick={isOpen ? toggle : null}
                >
                  Services
                </NavLink>
                <DropdownToggle nav   >  <CaretIcon  style={{ width: "7px",fill:"white" }} className="dropdown-toggle toggle-custom" /> </DropdownToggle>
              </div>
              <DropdownMenu className={`${isOpen ? "dropdown-menu-no" : "dropdown-menu-yes"} ${showTwo ? "show" : ""}`}   >
                <DropdownItem>
                  <HashLink
                    smooth
                    className="dropdown-item text-uppercase"
                    to={{ pathname: "/service", hash: "#sav" }}
                    onClick={toggle}
                  >
                    S.A.V
                  </HashLink>
                </DropdownItem>
                <DropdownItem>
                  <HashLink
                    smooth
                    className="dropdown-item text-uppercase"
                    to={{ pathname: "/service", hash: "#formation" }}
                    onClick={toggle}
                  >
                    Formation
                  </HashLink>
                </DropdownItem>
                <DropdownItem>
                  <HashLink
                    smooth
                    className="dropdown-item text-uppercase"
                    to={{ pathname: "/service", hash: "#support" }}
                    onClick={toggle}
                  >
                    Support Produit
                  </HashLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="nav-item mr-3  mt-2" onMouseEnter={dropThree} onMouseLeave={dropThree}>
            <div className="d-flex justify-between"   >
                <NavLink
                  to={{ pathname: "/a-propos", hash: "toppage" }}
                  className="nav-link-test mr-1 text-uppercase"
                  activeClassName="on"
                  onClick={isOpen ? toggle : null}
                >
                  à propos
                </NavLink>
                <DropdownToggle nav  >  <CaretIcon style={{ width: "7px",fill:"white" }}className=" dropdown-toggle toggle-custom" /> </DropdownToggle>

              </div>
              <DropdownMenu className={`${isOpen ? "dropdown-menu-no" : "dropdown-menu-yes"} ${showThree ? "show" : ""}`}  >
              <DropdownItem>
                  <HashLink
                    className="dropdown-item text-uppercase"
                    smooth
                    to={{ pathname: "/a-propos", hash: "presentation" }}
                    onClick={toggle}
                  >
                    Présentation
                  </HashLink>
               </DropdownItem>
                <DropdownItem>
                  <HashLink
                    className="dropdown-item text-uppercase"
                    smooth
                    to={{ pathname: "/a-propos", hash: "historique" }}
                    onClick={toggle}
                  >
                    Historique
                  </HashLink>
                </DropdownItem>
                <DropdownItem>
                  <HashLink
                    className="dropdown-item text-uppercase"
                    smooth
                    to={{ pathname: "/a-propos", hash: "partenaire" }}
                    onClick={toggle}
                  >
                    Partenaire
                  </HashLink>
                </DropdownItem>
                <DropdownItem>
                  <HashLink
                    className="dropdown-item text-uppercase" 
                    smooth
                    to={{ pathname: "/a-propos", hash: "clients" }}
                    onClick={toggle}
                  >
                    Nos Clients
                  </HashLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem className="nav-item  mt-2 mr-3">
              <NavLink
                to={{ pathname: "/contactez-nous", hash: "toppage" }}
                className="nav-link-test text-uppercase"
                activeClassName="on "
                onClick={toggle}
              >
                Contactez-nous
              </NavLink>
            </NavItem>
          </Nav>
          {/* <Auto /> */}
          <SearchBar  isOpen={isOpen} setIsOpen={setIsOpen} />
        </Collapse>
      </Navbar>
    </section>

  );
}

export default Navigation;
