import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";

function EditBanner({ match }) {
  const history = useHistory();
  const [banner, setBanner] = useState({
    uuid: "",
    imgPath: "",
    link: "",
  });
  useEffect(() => {
    Axios.get(`/produit/banner/${match.params.uuid}`)
      .then((res) => {
        setBanner(res.data[0]);
      })
      .catch((err) => console.log(err.message));
  }, [match.params.uuid]);
  const onChange = (e) => {
    setBanner({ ...banner, [e.target.name]: e.target.value });
  };
  const [img, setImg] = useState("");
  const [imgName, setImgName] = useState("Choisir Image");
  const onChangeImg = (e) => {
    setImg(e.target.files[0]);
    setImgName(e.target.files[0].name);
  };
  const updateBanner = async (e) => {
    e.preventDefault();
    const updatedBanner = new FormData();
    updatedBanner.append("banner", img);
    updatedBanner.append("uuid", banner.uuid);
    updatedBanner.append("imgPath", banner.imgPath);
    updatedBanner.append("link", banner.link);
    Axios.put(`/produit/banner/${match.params.uuid}`, updatedBanner)
      .then((res) => history.push("/gest-banner"))
      .catch((err) => console.log(err.message));
  };
  return (
    <div className="container">
      <div className="row row-a">
        <div className="col-md-8 col-lg-8">
          <h1>Modifier Images Des Covertures </h1>
          <br />

          <form onSubmit={updateBanner} encType="multipart/form-data">
            <div className="form-group">
              <label htmlFor="lien">Lien</label>
              <select
                className="form-control"
                id="lien"
                aria-describedby="emailHelp"
                name="link"
                value={banner.link}
                onChange={onChange}
              >
                <option value="/produit">Produit Page</option>
                <option value="/service">Service Page</option>
                <option value="/a-propos">A propos Page</option>
              </select>
            </div>

            <div className="custom-file mb-4 col-md-6 col-lg-6">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={onChangeImg}
              />
              <label className="custom-file-label" htmlFor="customFile">
                {imgName}
              </label>
            </div>
            <div className="custom-file  mt-3 mb-5 col-md-6 col-lg-6  text-right">
              <div>
                <img
                  src={`../../images/${banner.imgPath}`}
                  style={{ width: "40%", height: "40%" }}
                  alt=""
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn-button btn-secondary-button mt-3"
            >
              Modifier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBanner;
