import React, { useEffect, useState } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import Axios from "axios";
import { HashLink as Link } from "react-router-hash-link";

function CarouselHomePage() {
  const [Caroussel, setCaroussel] = useState([]);
  useEffect(() => {
    Axios.get("/produit/caroussel")
      .then((res) => {
        setCaroussel(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <Slider infinite autoplay={2000} className="slider-wrapper">
      {Caroussel.map((item,key) => (
        <div
          key={item.uuid}
          className="slider-content"
           key={key}
        >
           <img src={`../../images/${item.pathImage}`} />
          <div className="inner ml-5">
            <h5 id="title-carousel">{item.title}</h5>
            <p>{item.disc}</p>
            <Link to={item.lien}>
              <button id="button-carousel">En savoir plus</button>
            </Link>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default CarouselHomePage;
